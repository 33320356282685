$env: 1;
@import "../../../../assets/scss/app-mixins.scss";

.app-dialog {
  .modal-content {
    @include appBorderRadius(5px);
    box-shadow: none;
    .modal-body {
      padding: 0;
    }
  }
}
