$env: 1;
.wizard-header {
  border-radius: 10px;
  background: #024828;
  box-shadow: 0px 10px 25px 0px rgba(198, 196, 228, 0.2);
  display: flex;
  //   flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  .label {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin:0px;
    margin-left: 20px;
  }
}

.line {
  .wrapper {
    // border: 3px solid blue;
  }

  //   .container {
  //     background-color: pink;
  //   }

  .barCompleted {
    background-color: #4ab778;
    // width: 80%;
  }

  //   .label {
  //     font-size: 20px;
  //     color: green;
  //   }
}
