$env: 1;
@import "../../../../@core/scss/base/bootstrap-extended/include";
@import "../../../../@core/scss/base/components/include";

.signature {
  border: 2px solid;
  border-color: $primary;
  border-radius: 5px;

  width: 100%;
  height: 100px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}

input.mask.form-control {
  border-left: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
span.input-group-text {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important ;
}
.icon-date-picker {
  position: relative;
  .date-picker-input {
  }
  figure {
    position: absolute;
    right: 10px;
    bottom: -5px;
  }
}
.stepper-text-style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #1c2346;
  text-align: center;
  margin: 0;
}

.steper-plus-minus {
  display: flex;
  height: 30px;
  width: 30px;
  font-size: 30px;
  margin: 0;
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.steper-plus-minus:hover {
  transform: scale(1.1);
}

.chevron {
  cursor: pointer;
  margin-right: 10px;
}

.chevron:hover {
  transform: scale(1.1);
}

.date-picker-input {
  border-radius: 5px;
  padding: 4px 12px;
  background-color: white;
  height: 30px;
  border: 1px solid gainsboro;
  // box-shadow: 0 0 2px #0074d9;
}

.text-input-label {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 11px;
}

.input-style {
  border-radius: 8px !important;
  // border: 2px solid var(--black-1, #cfd3d4) !important;
  // background-color: white;
  overflow: hidden;
}

.button-filter {
  background-color: white !important;
  border-radius: 8px !important;
}

.title-style {
  color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 72.727% */
  letter-spacing: -0.22px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.file-header-style {
  display: flex;
  flex-direction: column;
}

.add-social-media {
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-top: 15px;
}

.submitted-page {
  position: relative;

  .submitHeader {
    position: absolute;
    top: 0;
    z-index: 999 !important;
    width: 100%;
    height: 60px !important;
  }

  .submit-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 40px;
    align-items: center;
    padding: 10% 25%;
    margin-top: 50px;
    .title {
      color: #170f49;
      text-align: center;
      font-family: Poppins;
      font-size: 30px;
      font-weight: 700;
    }
    .para {
      color: #6f6c90;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
    }
    button {
      background-color: #4ab778 !important;
      border: none !important;
    }
  }
}

.model-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
  padding-bottom: 30px;

  .select {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }
  p {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  .start {
    border-radius: 8px;
    background: #4ab778 !important;
    border: none !important;
  }
}

.input-style-phone {
  // background-color: #333 !important;
  width: 100% !important;
  height: 38px !important;
  border-radius: 8px !important;
}

.priority-select {
  border-radius: 5px !important;
  border: 0.5px solid gainsboro;
  color: #919395 !important;

  background: #fbfdfe !important;
}
.MuiList-padding {
  padding: 0 !important;
}

.important-chip {
  color: #919395;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
  border: 0.5px solid gainsboro;
  padding: 8px 20px;
  width: fit-content;
  margin-left: 20px;
  cursor: pointer !important;
  &-danger {
    color: #d75249;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    border: 0.5px solid #d75249;
    padding: 8px 20px;
    width: fit-content;
    margin-left: 20px;
    cursor: pointer !important;
  }
}

.social-icons {
  display: flex;
  justify-content: space-between;
  max-width: 40vh;
  margin: 20px 10px;
  height: 30px;

  .icon {
    cursor: pointer;
    &:hover {
      height: 30px;
    }
  }
}

.main-section {
  margin-bottom: 15px;
  .heading {
    margin: 0px;
    padding: 0px 10px;
    // margin-top: 30px;
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
  }
  .image-header {
    margin: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;

      .priority-pill {
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 9px 20px;
        border-radius: 5px;
        margin-left: 10px !important;
      }
      .Low {
        background: #f5f8e2 !important;
        color: #c5c903 !important;
      }

      .Medium {
        background: #f8efe2 !important;
        color: #ee9520 !important;
      }
      .High {
        background: #fff0ef;
        color: #d75249 !important;
      }
      .important-chip {
        color: #919395;
        font-family: Inter;
        font-size: 12px;
        font-weight: 700;
        border-radius: 5px;
        border: 0.5px solid gainsboro;
        padding: 8px 20px;
        width: fit-content;
        margin-left: 20px;
        cursor: pointer !important;
        &-danger {
          color: #d75249;
          font-family: Inter;
          font-size: 12px;
          font-weight: 700;
          border-radius: 5px;
          border: 0.5px solid #d75249;
          padding: 8px 20px;
          width: fit-content;
          margin-left: 20px;
          cursor: pointer !important;
        }
      }
    }

    .submit {
      background-color: #04433d !important;
      border: none !important;
      border-radius: 5px;
      color: #fff;
      font-family: Inter;
      font-size: 12px !important;
      font-weight: 500;
      width: 120px !important;
    }
  }
  &_info-container {
    display: flex;
    padding: 0px 16px;
    // flex-wrap: wrap;
    &_info {
      border: 1px solid #e2e2ea;
      min-height: 60px;
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      justify-content: center;
      gap: 5px;
      .info-head {
        color: #919395;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .info-content {
        line-break: anywhere;
        color: #161719;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
      }
    }
    &_file {
      margin: 0px 10px;
      width: 170px;
      border: 1px solid #e2e2ea;
      border-radius: 5px;
      .name {
        display: flex;
        align-items: center;
        // padding-left: 5px;
        figure {
          margin-top: 15px;
        }
        .title {
          margin: 10px 10px !important;
        }
      }
    }
  }
}
