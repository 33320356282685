$env: 1;
@import "../../../../assets/scss/app-mixins.scss";

.searchField {
  position: relative;
  margin-right: auto;
  width: 300px;
  height: 50px;
  background-color: #fff;
  overflow: hidden;
  .input-group {
    padding: 1rem;
    padding-right: 2rem; 
  }
  @include appBorderRadius(12px);
  .input-group-text {
    padding: 0;
    padding-left: 1rem;
  }
  .form-control {
    padding: 0px 1rem;
  }
  .form-control,
  .input-group-text {
    border: none;
    border-radius: 12px;
    background-color: transparent;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
  }

  .search-spinner {
    position: absolute;
    top: 15px;
    right: 10px;
  }
}
