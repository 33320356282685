$env: 1;
.file-import-wrapper {
  display: flex;
  flex-direction: column;
  //   align-items: center;
  justify-content: center;
  margin-left: 70px;
  .file-import-label {
    color: #000;
    font-family: Helvetica;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .file-import-sub-label {
    color: rgba(0, 0, 0, 0.4);
    font-family: Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
  }
}

.upload-icon-style {
  align-items: center;
  margin-left: 20px;
}
.select-file-wrapper {
  margin-right: 30px;
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }
  width: 100px;
  .select-file-btn {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #161719;
    background: #fbfdfe;
    cursor: pointer;
  }
}
