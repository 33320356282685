$env: 1;
.app-tabs {
  &--tab {
    cursor: pointer;
    position: relative;
    .tab-text {
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
    }
    &:not(:first-child) {
      margin-left: -21px;
    }
  }
}
/* styles.css or your custom CSS file */
.scroll-container {
  width: 90%; /* Set the desired width for the container */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent content from wrapping */
  // border: 1px solid #ccc; /* Optional: Add a border for visual clarity */
  // padding: 10px; /* Optional: Add padding for spacing */
}

.contenttab {
  display: inline-block; /* Display items in a row */
  // margin-right: 10px; /* Add spacing between items (optional) */
}
.containertab {
  display: flex; /* Use flexbox to align items in a row */
  align-items: center; /* Vertically center the items */
}
.columnClas {
  margin-left: 9px;
}
