$env: 1;
@import "../../../../assets/scss/app-mixins.scss";

.thirdPartyCompanies {
  padding: 0;
  &__header {
    @include appFlex(space-between);
    padding: 28px 58px 19px 67px;
    border-bottom: 1px solid #919395;
    h3 {
      color: #11142d;
      font-family: Poppins;
    }
    .searchField {
      background-color: #8f92a11a;
      margin: 0;
      min-width: 400px;
    }
  }
  &__body {
    padding: 19px 36px 19px 80px;
    &--item {
      margin-bottom: 30px;
      @include appFlex(space-between);
      .item-text {
        color: #55565c;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
      }
      .item-button {
        min-width: 165px;
        border-radius: 5px;
        border: 1px solid #4ab778 !important;
        color: #4ab778;
        font-family: Inter;
      }
    }
  }
  &__footer {
    padding: 19px 36px 28px;
    @include appFlex(space-between);
    .item-text {
      color: #5570f1;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}
