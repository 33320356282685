$env: 1;
@import "../../../../assets/scss/app-mixins.scss";
@mixin modalPaginationButton($deg: 90deg) {
  button {
    border: none;
    figure svg {
      transform: rotate($deg);
      -webkit-transform: rotate($deg);
      -moz-transform: rotate($deg);
      -ms-transform: rotate($deg);
      -o-transform: rotate($deg);
    }
  }
}
.pagination {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0;
  .page-item {
    .page-link {
      background-color: transparent;
      color: #667085 !important;
      &:not(:first-child),
      &:not(:last-child) {
        width: 40px;
        height: 40px;
      }
    }
    &.active {
      .page-link {
        color: #7f56d9 !important;
      }
      @include appBorderRadius();
    }

    &:first-child,
    &:last-child {
      .page-link {
        padding: 0px;
        button {
          padding: 8px 14px;
          @include paginationButton();

          svg {
            margin: 0;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    &:first-child {
      margin-right: auto;
      span {
        display: inline-block;
        margin-left: 5px;
      }
    }
    &:last-child {
      margin-left: auto;
      span {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  &.modal-pagination {
    .page-item {
      margin-right: 6px;

      .page-link {
        border-radius: 5px;
        color: #4f4f4f !important;
        box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
        width: 40px !important;
        height: 40px !important;
        padding: 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        button {
          background: transparent;
          padding: 0;
          box-shadow: none;
        }
      }

      &:first-child {
        margin-right: 12px;
        .page-link {
          @include modalPaginationButton(90deg);
        }
      }
      &:last-child {
        margin-left: 6px;
        @include modalPaginationButton(-90deg);

        .page-link {
          background-color: #333333;
          button {
            figure svg {
              path {
                fill: #fff !important;
              }
            }
          }
        }
      }
      &.active {
        .page-link {
          background-color: #333333;
          color: #fff !important;
        }
      }
    }
  }
}
