@import "../../../../assets/scss/app-mixins.scss";
.custom-list {
  border-radius: 20px 20px 0px 0px;

  .rdt_TableHeadRow {
    // background-color: black;
    padding: 20px 0px;
    // font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
  }
  // .dVgdrY {
  //   overflow: hidden !important;
  // }
  .rdt_Table {
    margin-left: 20px;
  }
}

.app-list {
  header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 10px;

    .btn {
      min-width: 165px;
      padding: 8px 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-color: #32936f;
      color: #32936f;
      svg {
        display: inline-block;
        margin-right: 5px;
        color: #32936f;
      }
    }
    .sortedBy {
      display: flex;
      align-items: center;
      .sort-label {
        color: #aebfc2;
        font-size: 0.9rem;
      }
      .MuiSelect-select {
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 10px;
        font-weight: 700;
      }
      .MuiSelect-icon {
        top: -1px;
      }
    }
  }
  .requests-list-STCS {
    @include appBorderRadius(0px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 5px 24px;
    margin-bottom: 10px;
    // flex-wrap: wrap;

    button {
      padding: 0;
      border: none;
      background-color: transparent;
      span {
        color: #4a4f60;
        display: inline-block;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }

    &--tabsContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &__tab {
        font-weight: 500;
        font-family: Inter;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
          margin-right: 1.5rem;
        }
        padding-bottom: 3px !important;
        &--text {
          font-size: 1rem;
          font-weight: 500;
        }

        &--count {
          min-width: 14px;
          min-height: 16px;
          padding: 0 5px;
          font-weight: 400;
          font-size: 0.8rem;
          background-color: #edebf4;
          color: #5d6375;
          margin-left: 10px;
          vertical-align: middle;
          @include appBorderRadius(13px);
        }

        &.active {
          font-weight: 700;
          color: #006938 !important;
          border-bottom: 2px solid #006938;
        }

        &.rejected-tab {
          .requests-list-STCS--tabsContainer__tab--text {
            color: #f9554b !important;
          }
          border-color: #f9554b !important;
        }
      }
    }
    &--columnSelector {
      margin-left: auto;
    }
  }
}

.app-primary-table {
  font-family: Inter;
  @include appBorderRadius(0px);
  .card-header {
    @include appBorderRadius(0px);
  }

  .card-header,
  .card-body,
  .card-footer {
    padding: 0 !important;
  }

  .card-body {
    .rdt_Table {
      .rdt_TableHead {
        .rdt_TableHeadRow {
          background-color: #fff !important;
          border: none;
          border-top: 1px solid #e4e7ec;
          height: 44px;
          .rdt_TableCol {
            .sc-fznKkj {
              color: #667085 !important;
              font-family: Inter !important;
              font-weight: 600 !important;
            }
          }
        }
      }

      .rdt_TableBody {
        //   border-bottom: 1px solid #e4e7ec;
        .rdt_TableRow {
          border: none;
          min-height: 72px;
          &:nth-child(odd) {
            background-color: #f9fafb;
          }
          .flatPicker-container {
            cursor: pointer;
            @include appFlex(space-between);
            .flatpickr-input {
              width: 85% !important;
              &:focus {
                border: none;
                outline: none;
              }
              border: none;
            }
            &--icon {
              width: 15%;
              visibility: hidden;
            }
            &:hover {
              .flatPicker-container--icon {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }
  &__column {
    &--header-with-icon {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        color: #667085 !important;
        font-family: Inter !important;
        font-weight: 600 !important;
        margin-right: 10px;
      }
      svg {
        color: #667085;
      }
    }
    &--view-and-download {
      display: flex;
      align-items: center;
      button {
        background-color: transparent;
        border: none;
        &:active,
        &:focus {
          border: none;
          outline: none;
        }
      }
      span {
        font-family: Inter;
        font-size: 1rem;
        font-weight: 600;
        display: inline-block;
      }
      .view {
        min-width: 50px;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.5rem;
        }
        .view-qt {
          min-width:  0px;
        }
      }
      .download {
        margin-left: 1rem;
        color: #6941c6;
      }
    }
    &--badge {
      padding: 10px;
      font-weight: 600;
      mix-blend-mode: multiply;
      @include appBorderRadius(20px);

      color: #006938 !important;
      background: rgba(225, 252, 239, 0.5) !important;
      &.brown {
        color: #bc951f !important;
        background: rgba(255, 248, 226, 0.5) !important;
      }
      &.blue {
        color: #5570f1 !important;
        background: rgba(85, 112, 241, 0.1) !important;
      }
      &.red {
        color: #f57e77 !important;
        background: rgba(245, 126, 119, 0.1) !important;
      }
    }
    &--dark-bold {
      color: black;
      font-size: 15px;
      font-weight: 500;
    }
  }
  .card-footer {
    padding: 14px 24px !important;
  }
}
