$env: 1;
@mixin respond($breakpoint) {
  @if $breakpoint == xs {
    @media only screen and (max-width: 36em) {
      @content;
    }
  }
  @if $breakpoint == sm {
    @media only screen and (max-width: 48em) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (max-width: 62em) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (max-width: 87.5em) {
      @content;
    }
  }
  @if $breakpoint == xxl {
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}

.check-email-screen {
  background-image: url(./assets/static/img/chekmailsceeen.svg);
  background-position: 100% 100%;
  background-size: cover;
  min-height: 100vh !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .headercontent {
    padding: 10px 10px 0px;
    .back-button {
      width: fit-content;
      figure {
        margin-right: 10px;
      }
      display: flex;
      align-items: center;
      color: #fff;
      cursor: pointer;

      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
    }
  }
  .box-content {
    text-align: center;
    background-color: #fff;
    height: 45vh;
    width: 55vh;
    display: flex;
    flex-direction: column;
    gap: 28px;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    @include respond(sm) {
      height: 35vh;
      width: 40vh;
      gap: 20px;
    }
    .icon {
      img {
        height: 100px;
        @include respond(sm) {
          height: 70px;
        }
      }
    }

    .message {
      color: #000;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      @include respond(sm) {
        font-size: 18px;
      }
    }

    .info {
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      @include respond(sm) {
        font-size: 14px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: end;
  }
}

.section {
  width: 55%;
  background-image: url(./assets/static/img/frame-37518.svg);
  background-position: 100% 100%;
  background-size: cover;
  min-height: 100vh !important;
  @include respond(md) {
    width: 50%;
  }
  @include respond(sm) {
    display: none;
  }
  .header-navigation {
    display: flex;
    justify-content: flex-end;
    margin: 15px 30px 0px 0px;
    .isolation-mode {
      height: 8vh;
    }
  }

  .container-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    .dashboard {
      height: 50vh;
      @include respond(md) {
        height: 35vh;
      }
    }
    .headline-description {
      height: 15vh;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      @include respond(md) {
        padding: 0px 20px;
      }
      .headline {
        align-items: flex-start;
        display: flex;
        color: #ffffff;
        font-family: "Inter", Helvetica;
        font-size: 35px;
        font-weight: 500;
        @include respond(md) {
          font-size: 27px;
        }
      }
      .text-wrapper {
        color: #ffffff;
        font-family: "Inter", Helvetica;
        font-size: 20px;
        font-weight: 400;
        @include respond(md) {
          font-size: 15px;
        }
      }
    }
  }
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  background-color: #fff;
  @include respond(md) {
    width: 50%;
  }
  @include respond(sm) {
    width: 100%;
    height: 100vh;
  }
  .header-navigation {
    display: flex;
    justify-content: flex-end;
    @include respond(sm) {
      justify-content: center;
    }
    padding: 32px;
    width: 100%;
    .isolation-mode {
      height: 8vh;
      @include respond(md) {
        height: 6vh;
      }
      @include respond(sm) {
        height: 8vh;
      }
    }
    .back-button {
      cursor: pointer;
      display: flex;
      align-items: center;
      figure {
        margin-right: 10px;
      }
      color: #000;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      &:hover {
        color: #006838;
      }
    }
  }
  .container-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    @include respond(md) {
      gap: 25px;
    }
    @include respond(sm) {
      gap: 32px;
    }

    .text-and-supporting {
      width: 450px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      @include respond(md) {
        width: 300px;
      }
      @include respond(xs) {
        width: 100% !important;
      }
      @include respond(sm) {
        width: 450px;
      }
      .text-2 {
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.72px;
        @include respond(md) {
          font-size: 30px;
        }
        @include respond(sm) {
          font-size: 36px;
        }
      }
      .supporting-text {
        color: #667085;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        // line-break: anywhere;
        @include respond(md) {
          font-size: 14px;
        }
        @include respond(sm) {
          font-size: 18px;
        }
      }
    }
    .form-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 10px;
      .input-field-instance {
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        background: #fff;
        width: 450px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        @include respond(md) {
          width: 300px;
        }
        @include respond(xs) {
          width: 100% !important;
        }
        @include respond(sm) {
          width: 450px;
        }
      }
      label {
        color: #344054;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        @include respond(md) {
          font-size: 13px;
        }
        @include respond(md) {
          font-size: 11px;
        }
        @include respond(sm) {
          font-size: 16px;
        }
      }
      .link-row {
        margin: 20px 0px;
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        .checkbox-container {
          display: flex;
          align-items: center;
          .text-3 {
            margin-left: 7px;
            font-family: "Inter-Medium", Helvetica;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .authButton {
        border-radius: 8px;
        border: 1px solid #006838;
        background: #006838;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        font-size: 16px;
        @include respond(md) {
          font-size: 16px;
        }
        @include respond(md) {
          font-size: 14px;
        }
        @include respond(sm) {
          font-size: 20px;
        }
      }
    }
  }

  .footer {
    align-items: center;
    align-self: stretch;
    background-color: transparent;
    display: flex;
    height: 96px;
    justify-content: flex-end;
    padding: 32px;
    width: 100%;
    @include respond(sm) {
      justify-content: center;
    }
  }

  .group {
    height: 67.08px;
    margin-bottom: -17.54px;
    margin-top: -17.54px;
    width: 120.79px;
    @include respond(md) {
      height: 50px;
    }
    @include respond(xs) {
      height: 40px;
    }
    @include respond(sm) {
      height: 67.08px;
    }
  }
}

.otp-custom {
  display: flex;
  justify-content: space-between;
  width: 450px;
  @include respond(sm) {
    width: 450px;
  }
  @include respond(xs) {
    width: 300px;
  }
}

.pulse-container {
  height: 100px;
  display: flex;
  justify-content: center;
  .progress-container {
    margin-left: -53px;
    position: relative;
    .react-circular-progressbar {
      position: absolute;
      width: 110px;
      height: 110px;
      border-radius: 50%;
      animation: pulse 1.5s infinite;
      background-color: rgba(215, 255, 232, 0.7);
      z-index: 0;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(215, 255, 232, 0.7);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 20px 20px rgba(215, 255, 232, 0.1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(215, 255, 232, 0.7);
  }
}

.link-code {
  color: #4ab778;
  font-family: Poppins;
  font-size: 16px;
}
