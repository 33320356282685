$env: 1;
.sync_imag_animate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.discard-btn {
  border-color: #ffeeee !important;
  background-color: #ffeeee !important;
  color: #8e4242 !important;
  box-shadow: none !important;
}

.discard-btn:focus {
  border-color: #f1dddd !important;
  background-color: #f1dddd !important;
  color: #8e4242 !important;
  box-shadow: none !important;
}
