$env: 1;
.widget-body {
  
  display: flex;
  justify-content: space-between;
  padding: 40px 2%;
  align-items: center;
  .widget-container {
    // display: flex;
    // flex-wrap: wrap;
    .title {
      width: 300px;
      font-size: 22px;
      font-weight: 700;
    }

    .count {
      font-size: 18px;
      color: #9da5b4;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .after {
    background: #9da5b4;
    height: 54px;
    width: 1px;

    &:last-child {
      display: none !important;
    }
  }

  @media (max-width: 790.98px) {
    .after {
      display: none;
    }
    flex-wrap: wrap;
    padding: 20px 50px;
    // align-items: center;
    // justify-content: center;
    .widget-container {
      margin: 20px;
    }
  }
}
.filter-body {
  background: #edf3fa !important;
  box-shadow: none !important;
}
.filter-label {
  display: flex;
  align-items: center;

  .text {
    color: black;
  }
}





