$env: 1;
@import "../../../../assets/scss/app-mixins.scss";
.listing-header {
  justify-content: flex-end;
  align-items: center;
  padding: 10px 24px !important;
  display: flex;
  background-color: #fff;
  .filter-label,
  .export-label {
    display: flex;
    align-items: center;
    span {
      color: black;
      font-weight: 600;
    }
  }
  .filter-label {
    margin-right: 1rem;
    max-width: 10%;
    min-width: 70px;
  }
  .export-label {
    max-width: 10%;
    min-width: 104px;

    // margin-left: auto;
    border: 2px solid #c3c3c3;
    background-color: transparent;
    padding: 4px 14px;
    &:disabled {
      opacity: 0.7;
    }
    span {
      display: inline-block;
      color: #888;
      margin-left: 10px;
    }
    svg {
      fill: #888;
      width: 20px !important;
      height: 20px !important;
    }
    .spinner-border {
      border-top-color: #888 !important;
      border-left-color: #888 !important;
      border-bottom-color: #888 !important;
      border-width: 2px;
      width: 20px !important;
      height: 20px !important;
    }
  }
  .selected-filters-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: calc(100% - 350px);
    margin-right: auto;
    overflow: auto;
    @include customScrollbar();
    .selected-filters-item {
      background: rgba(237, 237, 252, 0.5) !important;
      padding: 8px;
      display: flex;
      align-items: center;
      margin-right: 5px;
      text-wrap: nowrap;
      @include appBorderRadius(0px);
      span {
        font-weight: 600;
        display: inline-block;
      }
      .selected-filters--label {
        color: #828282;
        margin-right: 5px;
      }
      .selected-filters--values {
        color: #4f4f4f;
      }
      .selected-filters--crossButton {
        margin-left: 10px;
        color: #828282 !important;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
  }
  #addFilter {
    min-width: 113px;
    max-width: 10%;
    margin-left: 1rem;
  }
  .add-filter-button {
    border: none;
    background: rgba(225, 252, 239, 0.5);
    padding: 8px !important;
    span {
      font-weight: 600;
      color: #006938;
    }
    svg {
      width: 15px;
      height: 15px;
      color: #006938;
    }
  }
}

.filter-date {
  margin-right: 10px;
  position: relative;
  .date-picker-input {
    min-width: 250px !important;

    padding: 10px;
    border: none;
    background: #f4f5f6 !important;
    border-radius: 8px;
    height: 41px;
  }

  figure {
    position: absolute;
    top: 9px;
    right: 10px;
  }
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 620px) {
    justify-content: flex-start;
  }
}

