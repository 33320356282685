$env: 1;
@import "../../../assets/scss/app-mixins.scss";
.dashboard-filters {
  margin: -40px -30px 40px;
  justify-content: center !important;
}
#graph-dashboard {
  .dashboard-widgets {
    .clickable {
      cursor: pointer;
      @include appTransition();
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      &:hover {
        transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -o-transform: translateY(-5px);
      }
    }
  }
  .filter-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: white;
  }

  .date-picker {
    padding: 16px;
    color: black !important;
    font-size: 16px;
    font-weight: bold;
    width: 200px;

    background: #ffffff;
    border: 1px solid #f4f5f9;
    border-radius: 2px;
  }

  .flat-picker {
    padding: 16px;
    color: black !important;
    font-size: 16px;
    font-weight: bold;

    background: #ffffff;
    border: 1px solid #f4f5f9;
    border-radius: 2px;
    position: absolute;
    height: 68px;
    width: 290px;
    pointer-events: none;
    padding: 0;
    margin: 0;
  }
  .region-selector {
    padding: 16px;
    color: black !important;
    font-size: 16px;
    font-weight: bold;

    background: #ffffff;
    border: 1px solid #f4f5f9;
    border-radius: 2px;
    height: 68px;
    width: 290px;
    pointer-events: none;
    padding: 0;
    margin: 0;

    position: absolute;
    z-index: 1;
  }

  .selected-range {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #171b1e;
  }
  .radialBar-heading {
    position: absolute;
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #14172e;
    }
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 67px;
      color: #14172e;
    }
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #5b6774;
    }
  }

  .radialBar-rating {
    position: absolute;
    bottom: 22px;
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #5b6774;
    }
  }
  .submitted {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5b6774;
    margin: 0;
  }
  .submitted-value {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #5b6774;
    margin: 0;
  }
  .s-round {
    width: 15px;
    height: 15px;
    background: #4ab778;
    border-radius: 40px;
    margin-right: 12px;
    margin-top: 3px;
  }
  .s-round-no {
    width: 15px;
    height: 15px;
    background: #1c2346;
    border-radius: 40px;
    margin-right: 12px;
    margin-top: 3px;
  }

  .card-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #14172e;
  }

  .submission-per-region {
    .lhVPMq:not(:last-of-type) {
      border-bottom-style: none !important;
    }

    .rdt_TableHeadRow {
      background: #1c2346;
    }

    .sc-fznKkj {
      color: #ffffff !important;
    }

    .s-table {
      height: 10px;
      overflow: "auto";
      background-color: #787878;
    }
  }

  .sub-full-table {
    overflow-y: scroll;
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 33px;
      color: white;
      background-color: #4ab778;
      width: 100%;
      text-align: center;
      padding: 5px;
    }

    .sc-fznKkj {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 27px;
      color: #000000;
    }

    .id {
      width: 40px;
      height: 40px;
      background: rgba(227, 227, 227, 0.15);
      border-radius: 2px;
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #787878;
      }
    }
  }

  .transition {
    transition: all 0.2s;
  }

  .transition:hover {
    transform: scale(1.01);
  }

  .table-chart {
    .apexcharts-xaxis-texts-g {
      display: none !important;
    }

    .apexcharts-grid {
      display: none !important;
    }

    .apexcharts-xaxis {
      display: none !important;
    }
  }
  .apexcharts-menu-icon {
    display: none !important;
  }

  .css-b62m3t-container {
    input {
      width: 310px;
      background: #ffffff;
      height: 55px;
      padding: 0;
      margin: 0;
      border: 0;
      outline: 0;
    }

    .css-qc6sy-singleValue,
    .css-14el2xx-placeholder,
    input {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #171b1e !important;
    }

    input:focus {
      outline: none !important;
    }

    .css-1s2u09g-control {
      border-style: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    input[type="text"] {
      font-style: normal;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      color: #171b1e !important;
    }
  }

  .table-chart {
    height: 100px;
    width: auto;
  }
}

.nupco-Dashboard {
  // background-color: #4AB778;
  .region-selector {
    // padding: 16px;

    // background: #ffffff;
    // border: 1px solid #f4f5f9;
    // border-radius: 2px;
    // height: 68px;
    // width: 290px;
    // pointer-events: none;

    // position: absolute;
    // z-index: 1;

    .css-1s2u09g-control {
      padding: 13px;
      color: black !important;
      font-size: 16px;
      font-weight: bold;
    }
    .css-1pahdxg-control {
      padding: 13px;
      color: black !important;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .graph {
    // .transition {
    //   transition: all 0.2s;
    // }
    // .transition:hover {
    //   transform: scale(1.01);
    // }

    &_graphHeader {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      height: 35px;
      align-items: center;
      padding: 10px;
    }

    .radialBar-rating {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .submitted {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #5b6774;
      margin: 0;
    }
    .submitted-value {
      // font-style: normal;
      font-weight: 700;
      font-size: 16px;
      // line-height: 27px;
      color: #5b6774;
      margin: 0;
    }
    .s-round {
      width: 10px;
      height: 10px;
      border-radius: 40px;
      margin-right: 4px;
      margin-top: 5px;
    }
  }
  .transition {
    transition: all 0.2s;
  }

  .transition:hover {
    transform: scale(1.01);
  }
  .card-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #14172e;
  }
}

.card-no-shadow {
  box-shadow: none !important;
  border: 1px solid #f1f1f1;
  margin-bottom: 0 !important;
}

.site-accordion {
  .accordion-item {
    .accordion-header {
      border-bottom: 1px solid #f1f1f1;
      p {
        font-size: 24px;
        font-weight: 500;
        padding-left: 20px;
        padding-top: 9px;
        color: #000000;
      }
    }
    .accordion-collapse {
      .accordion-body {
        padding-top: 16px !important;
        padding-bottom: 0;
      }
    }
  }
}

.custom-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: #000000;
}

.sc-fznKkj {
  color: #ffffff !important;
}

.rdt_TableHeadRow {
  background: #1c2346 !important;
}

/////////////////////////////////////////////

.rcc-request-dashboard {
  background-color: #024828;
  height: 418px;
  .card-body {
    padding: 0;
    #chart {
      .apexcharts-canvas {
        // svg {
        //   g {
        //     transform: translate(0px) !important;
        //     -webkit-transform: translate(0px) !important;
        //     -moz-transform: translate(0px) !important;
        //     -ms-transform: translate(0px) !important;
        //     -o-transform: translate(0px) !important;
        //   }
        // }
      }
    }
  }
  .requestTitle {
    color: #7bfcb1;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 106.667% */
    letter-spacing: -0.15px;
    padding-bottom: 10px;
  }
  .requestCount {
    color: #7bfcb1;
    text-align: center;
    font-family: Inter;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 109.091% */
    letter-spacing: -1.32px;
    padding-bottom: 10px;
  }
  .requestDate {
    color: #959db2;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 10px;
  }
}
.totalrecord {
  .heading {
    color: #161719;
    text-align: center;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 133.333% */
    letter-spacing: -1.08px;
  }
  .title {
    color: #959db2;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 106.667% */
    letter-spacing: -0.15px;
  }
}
.performance {
  .heading {
    color: #006938;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.22px;
  }
  .subheading {
    color: #5b5b5b;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.requestStatus {
  border: 0.5px solid #eaeaea;
  // background: #FFF;
  .heading {
    font-style: normal;
    line-height: normal;
    font-family: Inter;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    // margin-top: 10%;
  }
  .title {
    color: #5b5b5b;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .percentage {
    color: #4ab778;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .icon {
    margin-right: 0.3rem;
  }
}
.faltPicker {
  display: flex;
  justify-content: start;
  align-items: center;
  border: 2px solid #d0d5dd;

  // background-color: #024828;
  width: 100%;
  padding-left: 10px;
  border-radius: 8px;
  .customCalander {
    padding: 5px;
    border: none !important;
    // background-color: #024828;
  }

  .active:focus-visible {
    outline: none !important;
  }
}
.customWidth {
  width: 250px !important;
}
// .rcc-request-dashboard {
//   // &_card {
//   &_body {
//     background-color: #024828;
//     // min-width: 327px;
//     height: 418px;
//     flex-shrink: 0;
//     text-align: center;
//     border-radius: 10px;
//     &-content {
//       margin-top: 45px;
//       .heading {
//         color: #7bfcb1;
//         font-family: Inter;
//         font-size: 15px;
//         font-style: normal;
//         font-weight: 600;
//         line-height: 16px; /* 106.667% */
//         letter-spacing: -0.15px;
//       }
//       .count {
//         color: #7bfcb1;
//         text-align: center;
//         font-family: Inter;
//         font-size: 44px;
//         font-style: normal;
//         font-weight: 600;
//         line-height: 48px; /* 109.091% */
//         letter-spacing: -1.32px;
//         // width: 136px;
//         height: 39px;
//         flex-shrink: 0;
//       }
//       .date {
//         color: #959db2;
//         font-family: Roboto;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: normal;
//       }
//     }
//   }
// }

// .compliance-dashboard {
//   margin-bottom: 20px;
//   &_card {
//     &_body {
//       padding: 18px 28px;
//       background-color: #ffffff;
//       position: relative;
//       height: 100px;
//       display: flex;
//       justify-content: space-between;
//       &-side {
//         background-color: #0fa045;
//         width: 10px;
//         height: inherit;
//         position: absolute;
//         left: 0;
//         top: 0;
//       }
//       &-content {
//         margin-top: 5px;
//         .heading {
//           color: #6b758a;
//           font-size: 18px;
//           font-weight: 500;
//         }
//         .count {
//           color: #261d56;
//           font-weight: 500;
//           font-size: 28px;
//         }
//       }
//     }
//   }
// }
