$env: 1;
@import "../../../../assets/scss/app-mixins.scss";

.thirdPartyCompanies {
  padding: 0;
  &__header {
    @include appFlex(space-between);
    padding: 28px 58px 19px 67px;
    border-bottom: 1px solid #919395;
    h3 {
      color: #11142d;
      font-family: Poppins;
    }
    .searchField {
      background-color: #8f92a11a;
      margin: 0;
      min-width: 400px;
    }
  }
  &__body {
    padding: 19px 36px 19px 80px;
    &--item {
      margin-bottom: 30px;
      @include appFlex(space-between);
      .item-text {
        color: #55565c;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
      }
      .item-button {
        min-width: 165px;
        border-radius: 5px;
        border: 1px solid #4ab778 !important;
        color: #4ab778;
        font-family: Inter;
      }
    }
  }
  &__footer {
    padding: 19px 36px 28px;
    @include appFlex(space-between);
    .item-text {
      color: #5570f1;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

.DepartmentsModal {
  color: #161719;
  .departmentDetailModal {
    &__header {
      @include appFlex();
      padding: 34px 44px;
      border-bottom: 1px solid #919395;
      &--icon {
        cursor: pointer;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 35px;
        }
      }
      &--title {
        color: #11142d;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
      }
    }
    &__governor {
      padding: 28px 66px 26px;
      border-bottom: 1px solid #919395;
      &--heading {
        display: inline-block;
        margin-bottom: 15px;
        color: #959db2;
        font-family: Inter;
        font-size: 15px;
        font-weight: 600;
      }
      &--text {
        color: #161719;
        font-family: Inter;
        font-size: 28px;
        font-weight: 600;
      }
    }
    &__widgets {
      border-bottom: 1px solid #919395;
      @include appFlex(center);
      .departmentDetailModalWidget {
        padding: 0px 0px;
        &:not(:last-child) {
          border-right: 1px solid #919395;
        }
        height: 172px;
        @include appFlex(center, center, column);

        @include fixWidth(250px);
        &--icon {
          margin-bottom: 10px !important;
        }
        &--count {
          margin-bottom: 15px;
          color: #11142d;
          font-family: Poppins;
          font-size: 36px;
          font-weight: 500;
        }
        &--text {
          color: #959db2;
          font-family: Inter;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    &__nationalities {
      padding: 28px 66px 26px;
      border-bottom: 1px solid #919395;
      @include appFlex(center, flex-start, column);
      .heading {
        color: #11142d;
        margin-bottom: 30px;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
      }
      &__content {
        @include appFlex();
      }

      .NationalityWidget {
        &:not(:last-child) {
          margin-right: 50px;
        }
        @include appFlex();
        @include fixWidth(136px);
        &--count {
          margin: 0;
          margin-left: 2rem;
          color: #11142d;
          font-family: Poppins;
          font-size: 22px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
    &__jobTitles {
      padding: 28px 66px 26px;
      margin-bottom: 4rem;
      .heading {
        color: #11142d;
        margin-bottom: 30px;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
      }
      &__content {
        @include appFlex();
      }
      .JobTitleWidget {
        &:not(:last-child) {
          margin-right: 50px;
        }
        @include appFlex(flex-start, flex-end);
        min-width: 110px;
        &--text,
        &--count {
          color: #11142d;
          font-family: Poppins;
          font-size: 22px;
          font-weight: 500;
          margin: 0;
        }

        &--count {
          margin: 0;
          margin-left: 10px;
          color: #006938;
        }
      }
    }
  }
}
