$env: 1;
@import "../../../../assets/scss/app-mixins.scss";
.company-details {
  padding: 0;
  .card-body {
    padding: 0px 30px;
  }
  &__widgets {
    @include appFlex(flex-start);
    .company-details__item {
      @include appFlex(flex-start, flex-start, column);
      width: calc(100% / 3);
      min-width: calc(100% / 3);
      min-height: 122px;
      max-height: 140px;
      padding: 30px;
      border-bottom: 1px solid #efefef;
      overflow: hidden;
      &:not(:last-child) {
        border-right: 1px solid #efefef;
      }
      &:nth-child(3) {
        border-right: none;
      }
      &.lastRow {
        border-bottom: none;
      }
      &--label {
        color: #828282;
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 11px;
        @include appTextEllipsis();
      }
      &--value {
        color: #066058;
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        @include appTextEllipsis();
      }
      .app-primary-table__column--badge {
        padding: 5px 10px;
        font-weight: 500;
      }
    }
    &.status {
      .company-details__item {
        width: calc(100% / 4);
        min-width: calc(100% / 4);
        &:not(:last-child) {
          border-right: 1px solid #efefef;
        }

        &--label {
          width: auto;
        }
      }
    }
  }
  &__tableContainer {
    margin-top: 70px;
    .app-tabs {
      margin-bottom: 30px;
    }
    .tableInfo {
      @include appFlex();
      margin-bottom: 20px;
      h4 {
        color: #101828;
        font-family: Inter;
        font-weight: 600;
        margin: 0;
      }

      .app-primary-table__column--badge {
        font-weight: 500;
        font-family: Inter;
        margin-left: 1rem;
      }
    }
    .app-primary-table {
      .card-body {
        .rdt_Table {
          .rdt_TableHead {
            .rdt_TableHeadRow {
              border: none;
              border-top: none !important;
              border-bottom: 1px solid #e4e7ec !important;
              background-color: #f9fafb !important;
            }
          }

          .rdt_TableBody {
            .rdt_TableRow {
              background-color: #fff;
              border-bottom: 1px solid #e4e7ec !important;
            }
          }
        }

        .project-column {
          @include appFlex();
          &--icon {
            width: 40px;
            height: 40px;
            background-color: rgba(225, 252, 239, 0.5);
            @include appFlex(center);
            @include appBorderRadius(20px);
            margin-right: 12px;
          }
          &--text {
            color: #101828;
            font-size: 1rem;
            font-family: Inter;
            font-weight: 600;
          }
        }
      }
      .pagination {
        .page-item {
          &.active {
            .page-link {
              color: #066058 !important;
              background-color: rgba(225, 252, 239, 0.5);
            }
          }
        }
      }
    }
  }
}
.custom-text-color {
  color: #1a40c8;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
