$env: 1;
/* Dropdown CSS Start */

.parent {
  position: relative;

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    z-index: 1000;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.24), 0 1.5px 6px rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    li {
      display: block;
      padding: 0px 22px;
      clear: both;
      height: 40px;
      color: #333;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .filter-label {
        padding: 0;
        .text {
          display: inline-block;
          margin-left: 1rem;
          font-size: 1rem;
          font-family: Inter;
        }
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
    hr {
      background-color: #e5e5e5;
      border: 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.12);
      margin: 0px;
    }
  }

  .dropdown-submenu {
    position: relative;
    .dropdown--item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        .text {
          color: #32936f !important;
        }
        svg {
          path {
            fill: #32936f !important;
          }
        }
      }
      .text {
        font-family: Inter;
        font-size: 0.8rem;
        color: #000;
        font-weight: 400;
      }
      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }
    .nested-menu {
      .dropdown--item {
        justify-content: flex-start;
        label {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }
  .dropdown-submenu .dropdown {
    top: 0px;
    left: 100%;
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
  }
  .dropdown-submenu:hover > .dropdown {
    visibility: visible;
    opacity: 1;
  }

  /* Dropdown CSS End */

  .dropdown {
    &.open {
      display: block;
      // position: absolute;
    }
  }
}
