$env: 1;
@import "../../../@core/scss/base/bootstrap-extended/include";
@import "../../../@core/scss/base/components/include";

.signature {
  // border: 2px solid;
  // border-color: $primary;
  // border-radius: 5px;

  width: 50%;
  height: 100px;
  overflow: hidden;

  &-canvas {
    width: 100%;
    height: 99px;
  }
}

#AuditDetails {
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 39px;
    color: #261d56;
  }
}

#user-details {
  .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #6e6b7b;
  }

  .item-value {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #6e6b7b;
  }
}

#medication-details {
  // h2 {
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 39px;
  //   color: #261d56;
  // }
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #000000;
  }
}

.pharmacy-available {
  background-color: $menu-dark-bg-color;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 5px;
  &-content {
    background-color: #fafafafa;
    padding: 10px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: $menu-dark-bg-color;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.badge-show {
  // background-color: red;
  padding: 2px 4px;
  color: white;
  border-radius: 7px;
  font-weight: 500;
  font-size: 11px;
}

.dottedBorderTop {
  border-top: 1px dotted black;
}

.show-container {
  display: flex;

  .show-page {
    background-color: #fff;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    width: 100%;
    margin-right: 20px;
    &-width {
      width: 100% !important;
    }
    .main-section {
      .heading {
        margin-top: 30px;
        color: #000;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
      }
      .image-header {
        margin: 20px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .head {
          display: flex;
          justify-content: start;
          align-items: center;
          gap: 10px;

          .priority-pill {
            font-family: Inter;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 9px 20px;
            border-radius: 5px;
            margin-left: 10px !important;
          }
          .Low {
            background: #f5f8e2 !important;
            color: #c5c903 !important;
          }

          .Medium {
            background: #f8efe2 !important;
            color: #ee9520 !important;
          }
          .High {
            background: #fff0ef;
            color: #d75249 !important;
          }
          .important-chip {
            color: #919395;
            font-family: Inter;
            font-size: 12px;
            font-weight: 700;
            border-radius: 5px;
            border: 0.5px solid gainsboro;
            padding: 8px 20px;
            width: fit-content;
            margin-left: 20px;
            cursor: pointer !important;
            &-danger {
              color: #d75249;
              font-family: Inter;
              font-size: 12px;
              font-weight: 700;
              border-radius: 5px;
              border: 0.5px solid #d75249;
              padding: 8px 20px;
              width: fit-content;
              margin-left: 20px;
              cursor: pointer !important;
            }
          }
        }

        .submit {
          background-color: #04433d !important;
          border: none !important;
          border-radius: 5px;
          color: #fff;
          font-family: Inter;
          font-size: 12px !important;
          font-weight: 500;
          width: 120px !important;
        }
      }
      &_info-container {
        display: flex;
        // flex-wrap: nowrap;
        &_info {
          border: 1px solid #e2e2ea;
          min-height: 60px;
          display: flex;
          flex-direction: column;
          padding-left: 30px;
          justify-content: center;
          gap: 5px;
          .info-head {
            color: #919395;
            font-feature-settings: "clig" off, "liga" off;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }

          .info-content {
            line-break: anywhere;
            color: #161719;
            font-feature-settings: "clig" off, "liga" off;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
          }
        }
        &_file {
          margin: 0px 10px;
          width: 170px;
          border: 1px solid #e2e2ea;
          border-radius: 5px;
          .name {
            display: flex;
            align-items: center;
            // padding-left: 5px;
            figure {
              margin-top: 15px;
            }
            .title {
              margin: 10px 10px !important;
            }
          }
        }
      }
    }
  }

  .time-line {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    width: 35% !important;
    height: fit-content;
    position: sticky;
  }

  @media (max-width: 1337px) {
    display: block;
    // background-color: #000000;
    .show-page {
      width: 100% !important;
    }

    .time-line {
      margin-top: 30px;
      padding: 30px;
      width: 100% !important;
    }
  }
}

.link-style {
  color: $primary;
  cursor: pointer;
}

.assin-model {
  max-width: 400px;
  .modal-content {
    .modal-header {
      background-color: #fff !important;
      color: black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }
    .modal-footer {
      .assign {
        border-radius: 5px;
        background: #32936f !important;
        border: none !important;
        padding: 8px 14px;
      }
      .cancel {
        border-radius: 5px;
        border: 1px solid #4ab778 !important;
        background-color: #fff !important;
        color: #4ab778 !important ;

        padding: 7px 14px;
      }
    }
    .search {
      position: relative;
      input {
        background-color: #00000008 !important;
        border-radius: 20px;
        height: 35px !important;
      }
      figure {
        position: absolute;
        right: 15px;
        top: 6px;
      }
    }
  }
}
.assignto-model {
  overflow: auto;
  .container-model {
    display: flex;
    align-items: center;
    justify-content: start;

    .content {
      margin-left: 20px;
      .name {
        color: #4b465c;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
      .email {
        color: #4b465c;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
}
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.checkbox-inner {
  width: 25px;
  height: 25px;
  border: 2px solid #d9d9d9;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .tick {
    content: "";
    width: 12px;
    height: 6px;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    position: absolute;
  }
}

.checkbox.checked .checkbox-inner {
  border-color: #32936f;
  background-color: #32936f;
  .tick {
    display: block;
  }
}

.swal2-modal {
  width: 45% !important;
  padding: 3%;
  .swal2-icon {
    border: none !important;
  }

  .swal2-actions {
    display: flex !important;
    // background-color: #04433d !important;
    gap: 20px;
    padding-bottom: 10px;
  }
}

.text-modal {
  .swal2-modal {
    padding: 10px !important;
    .swal2-html-container {
      .content-main {
        display: flex !important;
        flex-direction: column;
        align-items: start;
        .id {
          color: #11142d;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
        }
        textarea {
          width: 100%;
          font-size: 14px;
          border-radius: 10px !important;
          border-color: #cfd3d4 !important;
          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

.reopen {
  border-radius: 8px !important;
  background: #f57e77 !important;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  width: 100px;
  &:focus {
    box-shadow: none !important;
  }
}
.close {
  border-radius: 8px !important;
  background: #e2e2ea !important;
  color: #161719 !important;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  width: 100px;
  &:focus {
    box-shadow: none !important;
  }
}

.similar-request {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: space-evenly;

  .title {
    margin-top: 30px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
  }

  .bolder-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .center-content {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
  }
}

.revert-model {
  .content-main {
    display: flex;
    flex-direction: column;
    align-items: start;
    // background-color: #04433d;
    gap: 10px;
    .id {
      color: #11142d;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
    }
    textarea {
      font-size: 14px;
      border-radius: 10px !important;
      border-color: #cfd3d4 !important;
      flex-grow: 1 !important;
      &:focus {
        box-shadow: none !important;
      }
    }

    .react-select {
      // background-color: #000;
      .css-1s2u09g-control {
        width: 100% !important;
      }
    }

    .reason {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      text-align: left;
    }
  }
}

.remarks-container {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100% !important;
  align-items: end;
  .toast-container {
    width: 100% !important;

    display: flex;
    color: #eb5757;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #fda29b;
    background: #fffbfa;
    padding: 15px 25px;
    .hed {
      margin: 0;
      color: #b42318;
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;

      small {
        font-size: 11px !important ;
      }
    }

    .para {
      font-family: Inter;
      font-size: 14px;
      margin: 0;
      font-weight: 400;
    }
  }
  button {
    width: fit-content;
    border-radius: 10px !important;
    background: #024828 !important;
    border: none !important;
    margin-right: 20px;
  }
}

.file-viewr {
  .react-grid-Grid {
    height: 600px !important;
    // width: 600px !important;
    // overflow: auto;
  }
  .pg-viewer-wrapper {
    overflow-y: auto !important;

    .pg-viewer {
      .photo-viewer-container {
        height: fit-content !important;
        max-height: 600px !important;
        // height: 1000px !important;
        .photo {
          // height: 1000px !important;

          width: fit-content !important;
          max-height: 600px !important;
          height: fit-content !important;
        }
      }
    }
  }
}

.priority-dropdown {
  padding: 0 !important;
  background-color: white !important;
  border: none !important;
  &:hover {
    box-shadow: none !important;
  }
  &:active,
  &:focus {
    background-color: white !important;
  }
}

.item {
  width: 100% !important;
}

.additional-data {
  margin: 10px 0px;
  .head {
    font-size: 17px;
    font-weight: 600;
    margin-top: 20px;
    margin: 0;
    padding: 15px;
  }
  .data-container {
    display: flex;
    .info-head {
      color: #919395;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }

    .info-content {
      line-break: anywhere;
      color: #161719;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
    }
    .bord {
      border: 1px solid #e2e2ea;
      // margin: 0;
      padding: 0;
    }

    .name-container {
      display: grid;
      flex-direction: column;

      .names {
        border: 1px solid #e2e2ea;
        padding: 10px 30px;
        border-right: none !important;
        // height: 90px;
      }
    }

    .attachments {
      padding: 10px 30px;
      .links {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
    .sign {
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
}
